const EPSILON = 1e-4;

class AnimatedValue {
    constructor(target, current = 0, options = {}) {
        this.target = target;
        this.current = current;
        this.velocity = 0;

        this.constant = 100;
        this.damping = 20;
        this.mass = 1;
        this.active = true;

        Object.assign(this, options);
    }

    set(target) {
        this.target = target;
        this.active = true;
    }

    setCurrent(current) {
        this.current = current;
        this.active = true;
    }

    force(target) {
        this.target = target;
        this.current = target;
        this.active = false;
    }

    wiggle(amount) {
        this.current += amount;
        this.active = true;
    }

    update(dt) {
        if (!this.active) {
            return this.current;
        }

        const diff = this.current - this.target;
        if (Math.abs(diff) < EPSILON && Math.abs(this.velocity) < EPSILON) {
            this.current = this.target;
            this.active = false;
        } else {
            // https://gafferongames.com/post/spring_physics/
            // F = - kx - bv
            // k = spring constant, spring thightness
            // b = damping
            const force = -this.constant * diff - this.damping * this.velocity;
            // F = ma
            // a = F/m
            const acceleration = force / this.mass;

            this.velocity += acceleration * dt;
            this.current += this.velocity * dt;
        }

        return this.current;
    }
}

export default AnimatedValue;
