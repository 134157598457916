import React, { Component } from 'react';
import Visual from '../Visual/Visual';
import About from './About';
import DataKeys from './DataKeys';
//import { BrowserRouter as Router /*Route, Link */ } from 'react-router-dom';
import Color from 'color';
import { getHashParameters, getParamString } from '../../util/HashParameters';
import classnames from 'classnames';
import { getSumScoreSorted } from '../../util/CountrySort';

class App extends Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    Object.values(this.data.countries).forEach(country => {
      country.visible = true;
    });

    this.hash = '';

    this.countries = getSumScoreSorted(Object.values(this.data.countries)).map(
      c => c.iso,
    );

    // dna keys
    this.dnaKeys = ['performance', 'region'];

    this.dnaKeyTitles = {
      performance: 'UNDP Human Development Index (HDI)',
      region: 'Region',
    };
    this.dnaDataKeys = [
      {
        index: 37,
        name: this.dnaKeyTitles[this.dnaKeys[0]],
        notes:
          'WHO - Regional Office for Europe. European Health for All database (HFA-DB)',
        year: 'Most recent',
        source: 'HFA-DB: Indicator hfa_42',
        url:
          'https://gateway.euro.who.int/en/indicators/hfa_42-0500-undp-human-development-index-hdi/',
      },
      {
        index: 1,
        name: this.dnaKeyTitles[this.dnaKeys[1]],
        notes: 'Regions are based on the United Nation GeoScheme',
        source: 'United Nation Geoscheme',
        url: 'https://unstats.un.org/unsd/methodology/m49/',
      },
    ];

    const params = getHashParameters();

    this.initialState = {
      layout: 'dna',
      country: '',
      x: 459,
      y: 615,
      z: 37,
      a: 0,
      _hoverValue: null,
      _hoverCountry: '',
      _hoverX: 0,
      _hoverY: 0,
      _showAbout: false,
      _dataKey: '',
    };

    this.state = Object.assign({}, this.initialState, params);

    // region preload
    if ('regions' in this.state) {
      const hits = {};
      Object.values(this.data.countries).forEach(country => {
        hits[country.region] = true;
      });

      const keep = this.state.keep ? this.state.keep.split(',') : [];

      const regions = this.state.regions.split(',');
      if (regions.length > 0) {
        Object.keys(this.data.countries).forEach(key => {
          this.data.countries[key].visible =
            keep.includes(key) ||
            regions.includes(this.data.countries[key].region);
        });
      }
    }

    // create the visual
    this.visual = new Visual({
      setState: this.setState.bind(this),
    });
  }

  componentDidMount() {
    // create this.visual
    this.visual.load(this.data);
    this.visual.start();

    // pause if datakey active
    // if (this.state._dataKey) {
    // 	document.getElementById('visual').style.pointerEvents = 'none';
    // }

    // keyboard
    this.initKeyboard();

    // resize
    window.addEventListener('resize', () => {
      this.forceUpdate();
    });

    // history
    window.addEventListener('hashchange', () => {
      const params = getHashParameters();
      this.setState(params);
    });

    // store mouse coordinates
    document.addEventListener('mousemove', e => {
      if (this.state._hoverCountry || this.state._hoverValue) {
        this.setState({ _hoverX: e.pageX, _hoverY: e.pageY });
      }
    });

    // refresh visual state
    this.refreshVisual();
    this.refreshCountry();
    this.updateHash();
  }

  initKeyboard() {
    window.addEventListener('keydown', e => {
      if (e.target.nodeName.toLowerCase() !== 'body') {
        return;
      }

      switch (this.state.layout) {
        case 'dna':
          switch (e.key.toLowerCase()) {
            case 'arrowright':
              this.shiftA(1);
              break;
            case 'arrowleft':
              this.shiftA(-1);
              break;

            default:
          }
          break;
        case 'data':
          switch (e.key.toLowerCase()) {
            case 'arrowright':
              this.shiftX(1);
              break;
            case 'arrowleft':
              this.shiftX(-1);
              break;
            case 'arrowup':
              this.shiftY(1);
              break;
            case 'arrowdown':
              this.shiftY(-1);
              break;
            default:
          }
          break;
        case 'country':
          switch (e.key.toLowerCase()) {
            case 'arrowright':
              this.shiftCountry(1);
              break;
            case 'arrowleft':
              this.shiftCountry(-1);
              break;
            case 'arrowup':
              this.shiftY(1);
              break;
            case 'arrowdown':
              this.shiftY(-1);
              break;
            default:
          }
          break;

        case 'geo':
          switch (e.key.toLowerCase()) {
            case 'arrowright':
              this.shiftZ(1);
              break;
            case 'arrowleft':
              this.shiftZ(-1);
              break;

            default:
          }
          break;
        default:
      }
    });
  }

  shiftCountry(shift) {
    const keyLength = this.countries.length;
    let v = this.countries.indexOf(this.state.country) + shift;
    if (v >= keyLength) {
      v = 0;
    }
    if (v < 0) {
      v = keyLength - 1;
    }
    this.setState({ country: this.countries[v] });
  }

  shiftX(shift) {
    const keyLength = this.data.datakeys.length;
    let v = this.state.x + shift;
    if (v >= keyLength) {
      v = 0;
    }
    if (v < 0) {
      v = keyLength - 1;
    }
    this.setState({ x: v });
  }

  shiftY(shift) {
    const keyLength = this.data.datakeys.length;
    let v = this.state.y + shift;
    if (v >= keyLength) {
      v = 0;
    }
    if (v < 0) {
      v = keyLength - 1;
    }
    this.setState({ y: v });
  }

  shiftZ(shift) {
    const keyLength = this.data.datakeys.length;
    let v = this.state.z + shift;
    if (v >= keyLength) {
      v = 0;
    }
    if (v < 0) {
      v = keyLength - 1;
    }
    this.setState({ z: v });
  }

  shiftA(shift) {
    const keyLength = this.dnaKeys.length;
    let v = this.state.a + shift;
    if (v >= keyLength) {
      v = 0;
    }
    if (v < 0) {
      v = keyLength - 1;
    }
    this.setState({ a: v });
  }

  updateData() {
    this.setState({ layout: 'data' });
  }

  componentDidUpdate(prevProps, prevState) {
    // layout
    if (
      prevState !== this.state &&
      (this.state.x !== prevState.x ||
        this.state.y !== prevState.y ||
        this.state.z !== prevState.z ||
        this.state.a !== prevState.a ||
        this.state.layout !== prevState.layout)
    ) {
      this.refreshVisual();
      this.updateHash();
    }

    // country
    if (prevState !== this.state && this.state.country !== prevState.country) {
      if (this.state.layout === 'country') {
        this.refreshVisual();
      }
      this.refreshCountry();
      this.updateHash();
    }

    // datakeys
    if (
      prevState !== this.state &&
      this.state._dataKey !== prevState._dataKey
    ) {
      // if (this.state._dataKey) {
      // 	//this.state._dataKey || this.state._showAbout ? 'none' : 'auto,
      // 	document.getElementById('visual').style.pointerEvents = 'none';
      // 	//this.visual.viewport.pause = true;
      // } else {
      // 	document.getElementById('visual').style.pointerEvents = 'auto';
      // 	//	this.visual.viewport.pause = false;
      // }
    }
  }

  refreshCountry() {
    this.visual.setCountry(this.state.country);
  }

  refreshVisual(updateHash) {
    switch (this.state.layout) {
      case 'dna':
        this.visual.composer.setSize(0.25);

        this.visual.setLayout('dna', {
          a: this.dnaKeys[this.state.a],
        });

        break;
      case 'data':
        this.visual.setLayout('data', {
          x: this.state.x,
          y: this.state.y,
        });
        this.visual.composer.setSizeVar(this.state.z);
        break;
      case 'country':
        this.visual.setLayout('country', {
          x: this.state.x,
          y: this.state.y,
          country: this.state.country ? this.state.country : 'WRL',
        });
        if (!this.state.country) {
          this.setState({ country: 'WRL' });
        }
        break;
      case 'geo':
        this.visual.setLayout('geo');
        this.visual.composer.setSizeVar(this.state.z);
        // surface area
        //this.visual.composer.setSizeVar(1);
        break;
      case 'random':
        this.visual.setLayout('random');
        break;

      default:
        console.error('layout not found', this.state.layout);
    }
  }

  updateHash() {
    this.hash = '#' + getParamString(this.state);
    document.location.hash = this.hash;
  }

  setX = e => {
    this.setState({ x: e.target.value }, this.updateData);
  };

  setY = e => {
    this.setState({ y: e.target.value }, this.updateData);
  };

  setZ = e => {
    this.setState({ z: e.target.value });
  };

  getDataKey(n) {
    return this.data.datakeys[n];
  }

  render() {
    const hoverCountry = this.state._hoverCountry
      ? this.data.countries[this.state._hoverCountry]
      : null;

    return (
      <div className="App">
        <a
          className="tweet icon-twitter"
          href={
            'https://twitter.com/intent/tweet?text=' +
            encodeURIComponent(
              'Check out my findings on HEALTH|DNA - https://healthdna.sudox.nl/' +
                this.hash,
            )
          }
          target="_blank"
          rel="noopener noreferrer"
          title="Share on twitter"
        >
          Share on Twitter
        </a>
        {/* HEADER */}
        <header>
          <div className="logo-container">
            <div
              className="logo icon-logo"
              onClick={() => {
                this.setState(this.initialState);
              }}
            />
          </div>
          <div className="layouts">
            <div
              className={classnames('button icon-dna', {
                active: this.state.layout === 'dna',
              })}
              onClick={() => {
                this.setState({ layout: 'dna' });
              }}
              title="DNA"
            >
              DNA
            </div>
            <div
              className={classnames('button icon-data', {
                active: this.state.layout === 'data',
              })}
              onClick={() => {
                this.setState({ layout: 'data' });
              }}
              title="Data"
            >
              Data
            </div>
            <div
              className={classnames('button icon-country', {
                active: this.state.layout === 'country',
              })}
              onClick={() => {
                this.setState({ layout: 'country' });
              }}
              title="Country"
            >
              Country
            </div>
            <div
              className={classnames('button icon-geo', {
                active: this.state.layout === 'geo',
              })}
              onClick={() => {
                this.setState({ layout: 'geo' });
              }}
              title="Geographic"
            >
              Geographic
            </div>
          </div>

          <div
            className="about"
            onClick={() => {
              this.setState({ _showAbout: true });
            }}
          >
            <div>About</div>
          </div>
        </header>

        {/* DATA */}
        {this.state.layout === 'data' && (
          <div>
            {/* X */}
            <div className="label-x" key={'x-axis-' + this.state.x}>
              <span
                className="left icon-arrow-simple"
                onClick={() => {
                  this.shiftX(-1);
                }}
              />
              <span
                className="title"
                style={{
                  maxWidth: window.innerWidth - 100,
                }}
                onClick={() => {
                  this.setState({ _dataKey: 'x' });
                }}
              >
                {this.getDataKey(this.state.x).name}
              </span>
              <span
                className="right icon-arrow-simple"
                onClick={() => {
                  this.shiftX(1);
                }}
              />
            </div>

            {/* Y */}
            <div
              className="label-y"
              key={'y-axis-' + this.state.y}
              style={{
                right:
                  window.innerWidth / 2 -
                  this.visual.composer.getMarginX() +
                  Math.min(50, this.visual.composer.getMarginX() * 0.8),
              }}
            >
              <span
                className="left icon-arrow-simple"
                onClick={() => {
                  this.shiftY(-1);
                }}
              />
              <span
                className="title"
                style={{
                  maxWidth: window.innerWidth - 100,
                }}
                onClick={() => {
                  this.setState({ _dataKey: 'y' });
                }}
              >
                {this.getDataKey(this.state.y).name}
              </span>
              <span
                className="right icon-arrow-simple"
                onClick={() => {
                  this.shiftY(1);
                }}
              />
            </div>
          </div>
        )}

        {/* GEO */}
        {this.state.layout === 'geo' && (
          <div className="label-x" key={'z-axis-' + this.state.x}>
            <span
              className="left icon-arrow-simple"
              onClick={() => {
                this.shiftZ(-1);
              }}
            />
            <span
              className="title"
              style={{
                maxWidth: window.innerWidth - 100,
              }}
              onClick={() => {
                this.setState({ _dataKey: 'z' });
              }}
            >
              {this.getDataKey(this.state.z).name}
            </span>
            <span
              className="right icon-arrow-simple"
              onClick={() => {
                this.shiftZ(1);
              }}
            />
          </div>
        )}

        {/* DNA */}
        {this.state.layout === 'dna' && (
          <div>
            {/*A */}

            <div className="label-dna" key={'a-axis-' + this.state.a}>
              <span
                className="left icon-arrow-simple"
                onClick={() => {
                  this.shiftA(-1);
                }}
              />
              <span
                className="title"
                style={{
                  maxWidth: window.innerWidth - 100,
                }}
                onClick={() => {
                  this.setState({ _dataKey: 'a' });
                }}
              >
                {this.dnaKeyTitles[this.dnaKeys[this.state.a]]}
              </span>
              <span
                className="right icon-arrow-simple"
                onClick={() => {
                  this.shiftA(1);
                }}
              />
            </div>
          </div>
        )}

        {/* COUNTRY */}
        {this.state.layout === 'country' && (
          <div>
            {/*country */}

            <div className="label-x" key={'country-axis-' + this.state.a}>
              <span
                className="left icon-arrow-simple"
                onClick={() => {
                  this.shiftCountry(-1);
                }}
              />
              <span
                className="title"
                style={{
                  maxWidth: window.innerWidth - 100,
                }}
                onClick={() => {
                  this.setState({ layout: 'geo' });
                }}
              >
                {this.state.country in this.data.countries
                  ? this.data.countries[this.state.country].name
                  : ''}
              </span>
              <span
                className="right icon-arrow-simple"
                onClick={() => {
                  this.shiftCountry(1);
                }}
              />
            </div>
          </div>
        )}

        {/* COUNTRY HOVER - non-data pages */}

        {this.state.layout !== 'data' &&
          hoverCountry && (
            <div
              className="hover"
              style={{
                top: this.state._hoverY + 10,
                left: this.state._hoverX + 10,
                backgroundColor: Color(hoverCountry.color).hex(),
              }}
            >
              {hoverCountry.name}
            </div>
          )}

        {/* COUNTRY HOVER - data pages */}

        {this.state.layout === 'data' &&
          hoverCountry && (
            <div
              className="hover hover-country"
              style={{
                top: this.state._hoverY + 10,
                left:
                  this.state._hoverX > window.innerWidth - 350
                    ? this.state._hoverX +
                      10 -
                      (this.state._hoverX - (window.innerWidth - 350))
                    : this.state._hoverX + 10,
                backgroundColor: Color(hoverCountry.color).hex(),
              }}
            >
              <div className="country">{hoverCountry.name}</div>
              <div className="hover-value">
                <div className="label">
                  {this.data.datakeys[this.state.x].name}
                </div>
                <div className="value">
                  {hoverCountry.data[this.state.x].a}
                  <span>
                    {' / ' +
                      Math.round(hoverCountry.data[this.state.x].r * 100) +
                      '%'}
                  </span>
                </div>
              </div>
              <div className="hover-value">
                <div className="label">
                  {this.data.datakeys[this.state.y].name}
                </div>
                <div className="value">
                  {hoverCountry.data[this.state.y].a}
                  <span>
                    {' / ' +
                      Math.round(hoverCountry.data[this.state.y].r * 100) +
                      '%'}
                  </span>
                </div>
              </div>
            </div>
          )}

        {/* DNA HOVER */}

        {this.state._hoverValue && (
          <div
            className="hover hover-value"
            style={{
              top: this.state._hoverY + 10,
              left:
                this.state._hoverX > window.innerWidth - 350
                  ? this.state._hoverX +
                    10 -
                    (this.state._hoverX - (window.innerWidth - 350))
                  : this.state._hoverX + 10,
              borderLeft:
                '10px solid ' +
                Color(
                  this.data.countries[this.state._hoverValue.country].color,
                ).hex(),
            }}
          >
            <div className="label">{this.state._hoverValue.name}</div>
            <div className="value">
              {this.state._hoverValue.data.a}
              <span>{' / ' + this.state._hoverValue.data.r}</span>
            </div>
          </div>
        )}

        {/* ABOUT */}
        {this.state._showAbout && (
          <div className="modal-container">
            <div
              className="modal"
              onClick={() => {
                this.setState({ _showAbout: false });
              }}
            />
            <About
              onClick={() => {
                this.setState({ _showAbout: false });
              }}
            />
          </div>
        )}

        {/* DATA KEY SELECTOR */}
        {this.state._dataKey && (
          <div className="modal-container scroll">
            <div
              className="icon-close"
              onClick={() => {
                this.setState({ _dataKey: '' });
              }}
            />

            <div
              onClick={() => {
                this.setState({ _dataKey: '' });
              }}
            >
              <DataKeys
                active={
                  this.state._dataKey === 'a'
                    ? [this.state.a]
                    : [this.state.x, this.state.y, this.state.z]
                }
                datakeys={
                  this.state._dataKey === 'a'
                    ? this.dnaDataKeys
                    : this.data.datakeys
                }
                onClose={() => {
                  this.setState({ _dataKey: '' });
                }}
                onSelect={index => {
                  this.setState({
                    [this.state._dataKey]: index,
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
