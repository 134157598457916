const RegionIndex = {
	'Australia and New Zealand': 5,

	Micronesia: 6,
	Melanesia: 7,
	Polynesia: 8,

	'South America': 10,
	'Central America': 11,
	Caribbean: 11,
	'Northern America': 12,

	'Northern Europe': 21,
	'Western Europe': 22,
	'Southern Europe': 23,
	'Eastern Europe': 24,

	'Northern Africa': 31,
	'Western Africa': 32,
	'Middle Africa': 33,
	'Eastern Africa': 34,
	'Southern Africa': 35,

	'Western Asia': 41,
	'Southern Asia': 42,
	'Eastern Asia': 1,
	'South-Eastern Asia': 2,

	World: 100,
};

export default RegionIndex;
