import Layout from './Layout';

class CountryLayout extends Layout {
  layout({ x, y, country }) {
    const composer = this.composer;

    composer.hideGrid();
    composer.hideDNA();

    const width = composer.getWidth();
    const height = composer.getHeight();

    // get active country
    let activeCountry;
    composer.countries.forEach(c => {
      if (c.iso === country) {
        activeCountry = c;
      }
    });

    // x,y layout
    composer.countries.forEach(c => {
      if (c.iso !== country) {
        const diff = Math.abs(activeCountry.score - c.score);
        //const angle = Math.random() * Math.PI * 2;
        const angle = diff * Math.PI * 40;
        // duck
        c.props.x.set(Math.cos(angle) * (width * diff + width / 3));
        c.props.y.set(Math.sin(angle) * (height * diff + height / 3));
        c.props.alpha.set(0.05 + diff / 25);

        // difference to this country is leading in scale
        c.props.scale.set(1 - diff);

        // hide
        // c.props.x.set(composer.getFlyX());
        // c.props.y.set(composer.getFlyY());
        // c.props.alpha.set(0);
        //c.hideFlowers();
        c.container.visible = c.visible;
      } else {
        // show
        c.props.x.set(0);
        c.props.y.set(height / -6);
        c.props.alpha.set(1);
        const scale = Math.max(1, width / 720);
        c.props.scale.set(scale);
        c.canHover = false;
        c.scalar = 1;
        //c.showFlowers();

        const dnaWidth = width / scale;
        c.buildDNA(dnaWidth, -1);
        c.showDNA(0);

        // position DNA
        c.dna.x = -width / 2 / scale; // - 256 * c.renderScale;
        c.dna.y = height / 3 / scale;

        composer.bringCountryToTop(c);
        c.container.visible = c.visible;
      }
    });
  }
}

export default CountryLayout;
