export const getHashParameters = () => {
	if (!window.location.hash) {
		return {};
	}

	const result = {};

	var params = window.location.hash.substr(1).split('/');

	for (let i = 0, len = params.length, v; i < len && i + 1 < len; i += 2) {
		v = params[i + 1];
		result[params[i]] = !isNaN(v) ? Number(v) : decodeURI(v);
	}
	return result;
};

export const getParamString = (o = {}) => {
	const params = [];

	Object.keys(o).forEach(key => {
		// exclude _keys
		if (key.startsWith('_')) {
			return;
		}

		// add param
		params.push(encodeURI(key));
		params.push(encodeURI(o[key]));
	});

	return params.join('/');
};
