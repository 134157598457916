import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

// Load Data
fetch('/data/countries.json')
  .then(response => response.json())
  .then(data => {
    // Wait for font
    setTimeout(() => {
      if (document.fonts) {
        document.fonts.ready.then(() => {
          ReactDOM.render(<App data={data} />, document.getElementById('root'));
        });
      } else {
        ReactDOM.render(<App data={data} />, document.getElementById('root'));
      }
    }, 500);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
