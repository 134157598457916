import React, { Component } from 'react';
import debounce from 'debounce';

class DataKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      datakeys: this.props.datakeys,
    };

    this.updateData = debounce(this.updateData, 250);
  }
  // clear about-preview hash, so it doesn't trigger next time it should open
  componentDidMount() {
    if (document.location.hash === '#_dataKey/__') {
      document.location.hash = '';
    }
  }

  updateData = keyword => {
    const filter = keyword.toLowerCase();
    const datakeys =
      this.props.datakeys && filter
        ? this.props.datakeys.filter(
            d =>
              d.name.toLowerCase().indexOf(filter) > -1 ||
              d.notes.toLowerCase().indexOf(filter) > -1 ||
              d.source.toLowerCase().indexOf(filter) > -1,
          )
        : this.props.datakeys;

    this.setState({ datakeys });
  };

  render() {
    const keyword = this.state.keyword;

    return (
      <div
        className="datakeys-container"
        style={{
          marginLeft: Math.max((window.innerWidth - 40) / -2, -650 / 2),
        }}
      >
        <input
          type="text"
          placeholder="Filter..."
          onChange={e => {
            this.setState({ keyword: e.target.value });
            this.updateData(e.target.value);
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          value={keyword}
        />
        <List
          datakeys={this.state.datakeys}
          onSelect={this.props.onSelect}
          active={this.props.active}
        />
      </div>
    );
  }
}

class List extends React.PureComponent {
  render() {
    const { onSelect, active, datakeys } = this.props;

    return (
      <ul>
        {datakeys.map(d => (
          <li
            key={d.index}
            onClick={e => {
              onSelect(d.index);
            }}
            className={active.indexOf(d.index) > -1 ? 'active' : ''}
          >
            <h1>{d.name}</h1>
            <div className="details">
              {d.notes && <p className="notes">{d.notes}</p>}
              <p className="meta">
                {d.year && 'Year: ' + d.year}
                {d.year && <i>|</i>}
                {d.source && d.url && 'Source:'}{' '}
                {d.source &&
                  d.url && (
                    <a
                      href={d.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      {d.source}
                    </a>
                  )}
                {d.invert && (
                  <span
                    className="prop"
                    title="Visualization uses inverted value"
                  >
                    (inverted)
                  </span>
                )}
                {d.sqrt && (
                  <span
                    className="prop"
                    title="Visualization uses square root of value"
                  >
                    (sqrt value)
                  </span>
                )}
              </p>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default DataKeys;
