import Layout from './Layout';
import RegionIndex from '../../util/RegionIndex';

class DNALayout extends Layout {
  layout({ a }) {
    const composer = this.composer;

    composer.hideGrid();
    composer.hideFlowers();
    composer.enableCountryHover();

    const width = composer.getWidth() + composer.padding;
    const height = composer.getHeight() + composer.padding;
    const len = composer.countries.length;
    const cols = 3;
    const perCol = Math.ceil(len / cols);
    const colWidth = width / cols;
    const rowHeight = height / perCol;
    const padding = composer.padding / 2;

    let countries;
    switch (a) {
      case 'gdp':
        countries = this.getSortedCountries(8);
        break;
      case 'region':
        countries = this.getRegionSorted();
        break;
      default:
        countries = this.getSumScoreSorted();
    }

    // dna layout
    countries.forEach((c, i) => {
      // left-right: (padding + width / -2 + Math.floor(i / perCol) * colWidth),
      // right-left: padding + width / 2 - Math.floor(i / perCol) * colWidth - colWidth;
      c.props.x.set(padding + width / -2 + Math.floor(i / perCol) * colWidth);
      c.props.y.set(padding + height / -2 + (i % perCol) * rowHeight);

      // scale
      const scale = rowHeight / (512 * c.renderScale);
      c.props.scale.set(scale);
      c.props.alpha.set(1);

      // dna
      const dnaWidth = (colWidth - 5 - 512 * c.renderScale * scale) / scale;
      c.buildDNA(dnaWidth, -1);
      //c.showDNA((len - i) / len);
      c.showDNA(i / len);
      c.container.visible = c.visible;
    });
  }

  getSortedCountries(x) {
    return this.composer.countries.slice().sort((a, b) => {
      if (a.data[x].e === 1) {
        return b.data[x].e === 1 ? 0 : 1;
      }
      if (b.data[x].e === 1) {
        return -1;
      }

      return a.data[x].r > b.data[x].r ? -1 : 1;
    });
  }

  getRegionSorted() {
    return this.getSumScoreSorted().sort((a, b) => {
      if (RegionIndex[a.region] === RegionIndex[b.region]) {
        return 0;
      }
      return RegionIndex[a.region] < RegionIndex[b.region] ? -1 : 1;
    });
  }

  getSumScoreSorted() {
    return this.composer.countries.slice().sort((a, b) => {
      if (a.score === b.score) {
        return 0;
      }
      return a.score > b.score ? -1 : 1;
    });
  }

  getSumScoreSortedxx() {
    const skip = [0, 1, 7];
    return this.composer.countries.slice().sort((a, b) => {
      let tA = 0,
        cA = 0,
        tB = 0,
        cB = 0;

      a.data.forEach((d, index) => {
        if (skip.indexOf(index) >= 0 || d.e === 1) {
          return;
        }
        tA += d.r;
        cA++;
      });

      b.data.forEach((d, index) => {
        if (skip.indexOf(index) >= 0 || d.e === 1) {
          return;
        }
        tB += d.r;
        cB++;
      });

      return tA / cA > tB / cB ? -1 : 1;
    });
  }
}

export default DNALayout;
