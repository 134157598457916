import Layout from './Layout';

class GeoLayout extends Layout {
  layout(options = {}) {
    const composer = this.composer;

    composer.hideGrid();
    composer.hideDNA();

    const width = Math.min(window.innerHeight * 1, composer.getWidth());
    // limit height (for mobile screens)
    const height = Math.min(width * 0.8, composer.getHeight());

    // x,y layout
    composer.countries.forEach(c => {
      const empty = c.lat === c.lon && c.lon === 0;

      c.props.x.set(empty ? composer.getFlyX() : width * c.lon - width / 2);

      c.props.y.set(
        empty ? composer.getFlyY() : -(height * c.lat - height / 2),
      );

      c.props.alpha.set(empty ? 0.0 : 0.8);
      c.container.visible = c.visible;
    });
  }
}

export default GeoLayout;
