import Layout from './Layout';

class RandomLayout extends Layout {
	layout(options = {}) {
		const composer = this.composer;
		composer.showGrid();
		composer.hideFlowers();
		composer.enableCountryHover();

		this.composer.countries.forEach(c => {
			c.props.x.set(
				Math.random() * this.composer.getWidth() -
					this.composer.getWidth() / 2,
			);
			c.props.y.set(
				Math.random() * this.composer.getHeight() -
					this.composer.getHeight() / 2,
			);
			c.props.scale.set(Math.random());
			c.props.alpha.set(1);
		});
	}
}

export default RandomLayout;
