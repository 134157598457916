import Layout from './Layout';

class DataLayout extends Layout {
  layout({ x, y }) {
    const composer = this.composer;

    composer.showGrid();
    composer.hideDNA();
    composer.hideFlowers();
    composer.enableCountryHover();

    const width = composer.getWidth();
    const height = composer.getHeight();

    // x,y layout
    composer.countries.forEach(c => {
      const empty = c.data[x].e || c.data[y].e;

      c.props.x.set(
        empty ? composer.getFlyX() : width * c.data[x].r - width / 2,
      );

      c.props.y.set(
        empty ? composer.getFlyY() : -(height * c.data[y].r - height / 2),
      );

      c.props.alpha.set(empty ? 0.0 : 0.8);
      c.container.visible = c.visible;
    });
  }
}

export default DataLayout;
