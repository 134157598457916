import * as PIXI from 'pixi.js';
import AnimatedValue from '../../util/AnimatedValue';
import Color from 'color';

const tintDark = 0x443d1f;

class Country {
  constructor(options) {
    Object.assign(this, options);

    // scale the circle/text is rendered in to make 0.1..1.00 range ok on screens
    this.renderScale = Math.max(
      0.1,
      Math.min(0.17, 0.17 * (window.innerWidth / 1600)),
    );

    // modifier for scalar
    this.scalar = 1;

    // dna sprites
    this.dnaSprites = [];
    this.hoverSprite = null;
    this.hoverDNAFactor = 0.45;
    this.dnaInteraction = false;

    this.lastPointerDown = 0;
    this.pointerDownTimer = 0;

    this.active = false;

    this.canHover = true;

    // flowers
    this.flowerSprites = [];
    this.hoverFlower = null;
    this.flowersInitialized = false;

    // animated properties
    this.props = {
      x: new AnimatedValue(0, 0, { mass: 1 + Math.random() * 0.5 }),
      y: new AnimatedValue(0, 0, { mass: 1 + Math.random() * 0.5 }),
      alpha: new AnimatedValue(0, 0),
      dnaAlpha: new AnimatedValue(0, 0),
      scale: new AnimatedValue(0, 0),
      circleScale: new AnimatedValue(1, 0, { mass: 0.5, constant: 500 }),
    };

    this.initContainer();
  }

  initContainer() {
    // Container
    const container = new PIXI.Container();
    // save to class
    this.container = container;

    // initially hide
    container.alpha = 0;
    container.scale.x = 0;
    container.scale.y = 0;
    container.blendMode = PIXI.BLEND_MODES.MULTIPLY;

    // interaction
    container.interactive = true;
    container.buttonMode = true;

    // circle container
    this.circle = new PIXI.Container();
    this.container.addChild(this.circle);
    this.circle.interactive = true;

    // mouse enter
    this.circle.on('pointerover', () => {
      if (!this.canHover) {
        return;
      }
      this.props.circleScale.set(1.5);
      this.onCountryMouseOver(this);
    });

    // mouse leave
    this.circle.on('pointerout', () => {
      if (!this.canHover) {
        return;
      }
      this.props.circleScale.set(1);
      this.onCountryMouseOut(this);

      // timeout
      clearTimeout(this.pointerDownTimer);
    });

    // mouse down -- required for preving tap when dragging viewport
    this.circle.on('pointerdown', e => {
      this.lastPointerDown = new Date().getTime();
      this.pointerDownTimer = setTimeout(() => {
        this.onCountryMouseDown(this, false);
      }, 300);
    });

    this.circle.on('pointerup', e => {
      clearTimeout(this.pointerDownTimer);
    });

    // mouse down
    this.circle.on('pointertap', () => {
      // prevent registering dragging the viewport as a tap
      if (new Date().getTime() - this.lastPointerDown > 300) {
        return;
      }
      this.props.circleScale.set(1);
      this.props.alpha.setCurrent(0.7);
      this.onCountryMouseDown(this, true);
    });

    // active aura
    this.activeAura = PIXI.Sprite.fromImage('/images/sprite_512.png');
    this.activeAura.anchor.set(0.5);
    this.activeAura.tint = this.color;
    this.activeAura.alpha = 0.5;
    this.activeAura.scale.x = this.activeAura.scale.y = this.renderScale * 2;
    this.activeAura.visible = false;
    this.circle.addChild(this.activeAura);

    // Circle sprite: c
    const c = PIXI.Sprite.fromImage('/images/sprite_512.png');
    c.anchor.set(0.5);

    // way to generate nice random color
    //c.tint = Math.random() * 0xffffff;
    c.tint = this.color;

    // scale circle
    c.scale.x = c.scale.y = this.renderScale;

    c.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    this.c = c;
    this.circle.addChild(c);

    // Text: t
    const t = new PIXI.Text(this.iso, {
      fontFamily: 'Rubik, sans',
      fontSize: (24 / 0.17) * this.renderScale, // (0.17 * 24) / this.renderScale,
      fontWeight: 'bold',
      fill: 0xffffff,
      align: 'center',
    });
    t.anchor.set(0.5);
    this.t = t;
    this.circle.addChild(t);

    // Extra
    this.extra = new PIXI.Container();
    container.addChild(this.extra);

    // DNA
    this.dna = new PIXI.Container();
    this.dna.interactive = true;
    this.dna.on('pointerover', () => {
      if (!this.dnaInteraction) {
        this.startDNAInteraction();
      }
    });
    this.dna.on('pointerout', () => {
      if (this.dnaInteraction) {
        this.stopDNAInteraction();
      }
    });
    this.dna.on('pointerdown', () => {
      if (!this.dnaInteraction) {
        this.startDNAInteraction();
      }
    });
    this.extra.addChild(this.dna);

    this.flowers = new PIXI.Container();
    this.flowers.visible = false;
    this.circle.addChildAt(this.flowers, 0);
  }

  addIcon(url) {
    this.t.visible = false;

    this.icon = PIXI.Sprite.fromImage(url);
    this.icon.anchor.set(0.5);

    // scale circle
    this.icon.scale.x = this.icon.scale.y = this.renderScale * 3;

    this.circle.addChild(this.icon);
  }

  setActive(a) {
    this.active = a;
    this.activeAura.visible = a;
    //a ? this.showFlowers() : this.hideFlowers();
  }

  showFlowers() {
    if (!this.flowersInitialized) {
      this.initFlowers();
    }
    this.flowers.visible = true;
  }

  // Flower
  initFlowers() {
    //Flower;
    const f = new PIXI.Graphics();
    f._country = this;
    f._hover = false;

    f.tint = this.color;
    f.opacity = 0.1;

    this.flowerSprites.push(f);
    this.flowers.addChild(f);
    f.beginFill(0xffffff, 0.5);

    const step = (2 * Math.PI) / this.data.length;
    const halfStep = step / 2;
    const size = 1.1 * 256 * this.renderScale;
    const radius = 256 * this.renderScale;
    const rotate = -0.5 * Math.PI;
    const petal = 2;

    for (let i = 0, len = this.data.length, v1, v2, v3; i < len; i++) {
      if (i === 0) {
        v1 = radius + (size * (this.data[len - 1].r + this.data[i].r)) / petal;
      } else {
        v1 = radius + (size * (this.data[i - 1].r + this.data[i].r)) / petal;
      }

      v2 = radius + size * this.data[i].r;

      if (i === len - 1) {
        v3 = radius + (size * (this.data[0].r + this.data[i].r)) / petal;
      } else {
        v3 = radius + (size * (this.data[i + 1].r + this.data[i].r)) / petal;
      }

      // iit
      if (i === 0) {
        f.moveTo(
          Math.cos(rotate + i * step - halfStep) * v1,
          Math.sin(rotate + i * step - halfStep) * v1,
        );
      }

      f.bezierCurveTo(
        Math.cos(rotate + i * step - halfStep) * v1,
        Math.sin(rotate + i * step - halfStep) * v1,
        Math.cos(rotate + i * step) * v2,
        Math.sin(rotate + i * step) * v2,
        Math.cos(rotate + i * step + halfStep) * v3,
        Math.sin(rotate + i * step + halfStep) * v3,
      );
    }

    this.flowersInitialized = true;
  }

  hideFlowers() {
    this.flowers.visible = false;
  }

  // DNA

  initDNA() {
    // Only runs once!
    // Create the sprites
    if (this.dnaSprites.length === 0) {
      this.data.forEach((d, index) => {
        const sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
        sprite._index = index;
        sprite._country = this;
        sprite._hover = false;

        // add
        this.dnaSprites.push(sprite);
        this.dna.addChild(sprite);

        // style
        if (d.e === 1) {
          // empty
          sprite.alpha = 0.5;
          sprite.tint = tintDark;
        } else {
          // with data
          const color = Color(tintDark);
          sprite.tint = color
            .mix(Color(this.color), d.r * 1.25)
            .darken(d.r)
            .rgbNumber();
          sprite.alpha = d.r;
        }

        // interaction
        //sprite.interactive = true;
        //sprite.buttonMode = true;

        // mouse enter
        sprite.on('pointerover', e => {
          this.startDNAHover(sprite);
        });

        // mouse leave
        sprite.on('pointerout', e => {
          this.stopDNAHover(sprite);
        });

        // mouse down -- required for preving tap when dragging viewport
        sprite.on('pointerdown', e => {
          this.lastPointerDown = new Date().getTime();
        });
        // click
        sprite.on('pointertap', e => {
          // prevent registering dragging the viewport as a tap
          if (new Date().getTime() - this.lastPointerDown > 300) {
            return;
          }
          e.stopPropagation();
          const ctrlKey =
            e.data &&
            e.data.originalEvent &&
            e.data.originalEvent.ctrlKey &&
            e.data.originalEvent.ctrlKey === true;
          const shiftKey =
            e.data &&
            e.data.originalEvent &&
            e.data.originalEvent.shiftKey &&
            e.data.originalEvent.shiftKey === true;
          this.onDNAMouseDown(this, index, ctrlKey | shiftKey);
        });
      });
    }

    // Blur filter for visual effect
    // this.dnaBlurFilter = new PIXI.filters.BlurFilter();
    // this.dnaBlurFilter.blur = 0.5;
    // this.dna.filters = [this.dnaBlurFilter];
  }

  stopDNAHover(sprite) {
    if (sprite !== null && sprite !== this.hoverSprite) {
      return;
    }
    this.dnaSprites.forEach(s => {
      if (s._hover) {
        s.alpha /= this.hoverDNAFactor;
        s._hover = false;
      } else {
        // inform composer
        this.onDNAMouseOut(this, -1);
      }
    });
  }

  startDNAHover(sprite) {
    this.stopDNAHover(null);
    this.hoverSprite = sprite;
    this.dnaSprites.forEach(s => {
      if (s !== sprite) {
        if (!s._hover) {
          s.alpha *= this.hoverDNAFactor;
          s._hover = true;
        }
      } else {
        const d = this.data[s._index];
        s.alpha = d.e === 0 ? d.r : 0.5;
      }
    });

    // inform composer
    this.onDNAMouseOver(this, sprite._index);
  }

  getDNASize() {
    return 512 * this.renderScale;
  }

  buildDNA(width, x) {
    if (this.dnaSprites.length === 0) {
      this.initDNA();
    }
    const size = this.getDNASize();
    const step = width / (this.data.length + 1);

    // restore position
    this.dna.x = size / 2;
    this.dna.y = 0;

    // skip is used to create margin
    // around the active x-element
    let skip = 0;

    // update sprite position/size
    for (let i = 0, len = this.data.length, sprite; i < len; i++) {
      sprite = this.dnaSprites[i];

      if (this.data[i].e === 1) {
        sprite.x = skip + step / 2 - 2 + i * step;
        sprite.y = -2;
        sprite.scale.x = 0.4;
        sprite.scale.y = 0.4;
        sprite._scale = sprite.scale.x;
        continue;
      }

      const marginY = i === x ? 4 : 10;
      if (i === x) {
        skip += 4;
      }
      sprite.x = skip + i * step;
      sprite.y = -size / 2 + marginY / 2;
      sprite.scale.x = (step + (i === x ? step : 0)) / 10;
      sprite.scale.y = (size - marginY) / 10;
      sprite._scale = sprite.scale.x;

      if (i === x) {
        skip += 4 + step;
      }
    }
  }

  showDNA(delay) {
    if (this.dnaSprites.length === 0) {
      this.initDNA();
    }

    this.props.dnaAlpha.force(-100);
    this.props.dnaAlpha.damping = 20 + delay * 20;
    this.dna.alpha = 0;
    this.dna.scale.y = 1;
    this.props.dnaAlpha.constant = 100; //150 - 100 * delay; //50 + Math.random() * 100;
    this.props.dnaAlpha.set(1);
    this.dna.visible = true;
  }

  hideDNA() {
    this.dna.visible = false;
    this.stopDNAInteraction();
  }

  stopDNAInteraction() {
    this.dnaInteraction = false;
    this.stopDNAHover(null);
    this.dnaSprites.forEach(s => {
      s.interactive = false;
      s.buttonMode = false;
    });
  }

  startDNAInteraction() {
    this.dnaInteraction = true;
    this.dnaSprites.forEach(s => {
      s.interactive = true;
      s.buttonMode = true;
    });
  }

  // Animation
  animate(dt, elapsed) {
    this.container.x = this.props.x.update(dt);

    this.container.y = this.props.y.update(dt);

    this.container.alpha = this.props.alpha.update(dt);

    this.container.scale.x = this.container.scale.y =
      this.props.scale.update(dt) * this.scalar;

    this.circle.scale.x = this.circle.scale.y = this.props.circleScale.update(
      dt,
    );

    // dna
    if (this.dna.visible) {
      this.dna.alpha = Math.max(0, Math.min(1, this.props.dnaAlpha.update(dt)));

      // can hover is in layout=country mode;
      // this is a small hack
      if (!this.canHover) {
        this.dna.scale.y = this.dna.alpha;
      } else {
      }
    }

    // active
    if (this.active) {
      this.activeAura.alpha = 0.5 + Math.sin(elapsed * 0.0025) * 0.15;
      this.activeAura.scale.x = this.activeAura.scale.y =
        this.renderScale * 2 + (0.65 - this.activeAura.alpha) * 0.05;
    }

    // wiggle
    // const f = 0.1;
    // this.props.x.wiggle((Math.random() - 0.5) * f);
    // this.props.y.wiggle((Math.random() - 0.5) * f);

    // if (Math.random() > 0.96) {
    // 	//this.props.scale.wiggle((Math.random() - 0.5) * 0.05);
    // }
  }
}

export default Country;
